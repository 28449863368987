export const version = '3.0.0 pt 5.2: June 27, 2024: Fall of the Octeracts 2'

/**
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 */
// eslint-disable-next-line
export const testing: boolean = false;
export const lastUpdated = new Date(Date.UTC(2024, 4, 28, 16, 0, 6))
/**
 * CHANGE THIS ONE INSTEAD
 */
export const prod: boolean = false
